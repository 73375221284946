<!-- 表格列自定义配置 -->
<template>
    <div class="dialog-content">
        <div class="content" id="dragTable">
            <el-table  class="table" ref="dragTable" :data="dialog.data.tableData" 
              :key="tableKey" row-key="columnId" height="500" border
              :row-class-name="tableRowClassName"
            >
                <el-table-column
                    align="center"
                    width="60"
                    label="序号"
                  >
                    <template slot-scope="scope">
                      <div>
                        {{ scope.$index + 1 }}
                      </div>
                    </template>
                </el-table-column>
                <el-table-column  
                    label="显示" width="60" align="center">
                    <template slot-scope="scope">
                      <!-- 序号列和必填列一定要显示 -->
                      <el-checkbox class="width80" v-model="scope.row.fieldIsDisplay" :disabled="scope.row.fieldCaption=='序号'||scope.row.isRequired">
                    </el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column
                  label="默认文本"
                  align="center"
                  prop="fieldCaption"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  label="自定义文本"
                  align="center"
                  prop="fieldCustomCaption"
                  min-width="100"
                >
                  <template slot-scope="scope">
                      <el-input size="mini" maxlength="16" v-model="scope.row.fieldCustomCaption"/>
                  </template>
                </el-table-column>
                <el-table-column
                  label="自定义宽度(px)"
                  align="center"
                  prop="fieldCustomWidth"
                  min-width="80"
                >
                  <template slot-scope="scope">
                      <el-input size="mini" v-model="scope.row.fieldCustomWidth" 
                          @input="inputLimit($event, 3,0, dialog.data.tableData[scope.$index],'fieldCustomWidth')"
                          @blur="handleBlurInput($event,scope.$index,'fieldCustomWidth')"
                      />
                  </template>
                </el-table-column>
                <el-table-column
                  property="operate"
                  label="排序"
                  width="80px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <i class="el-icon-rank" 
                      :class="{'dragicon':!scope.row.fieldFixedFront&&!scope.row.fieldFixedBack,'icon-rank-left':scope.row.fieldFixedFront,'icon-rank-right':scope.row.fieldFixedBack}" 
                      style="cursor: pointer;"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column
                  property="operate"
                  label="固定列"
                  width="100px"
                  align="center"
                >
                  <template v-slot="scope">
                    <div class="operate x-fsa">
                      <!-- 固定左 -->
                      <div class="operate-item x-fc" @click="handleTopside(scope)"  :class="{'operate-icon':scope.row.fieldFixedFront}">
                        <svg-icon icon-class="fixed-left" :class="{'icon-active':scope.row.fieldFixedFront}"/>
                      </div>
                      <!-- 固定右 -->
                      <div class="operate-item x-fc" @click="handleNethermost(scope)"  :class="{'operate-icon':scope.row.fieldFixedBack}">
                        <svg-icon icon-class="fixed-right" :class="{'icon-active':scope.row.fieldFixedBack}"/>
                        <!-- <i class="el-icon-d-arrow-right my-el-icon"></i> -->
                      </div>
                    </div>
                  </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <div class="dialog-footer-box x-bw">
            <el-button type="primary" @click="handleInit">初始化默认值</el-button>
            <div>
              <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
              <el-button type="primary" @click="$emit('dialogEvent', 'dialogChange',dialog.data.tableData)">确 定</el-button></div>
          </div>
        </div>
    </div>
</template>
<script>
import {inputLimit} from "@/utils/numFormatting";//数字类型文本框输入限制
import {arrayObjectCopy} from "@/utils/index";
import { mixin } from "@/components/Dialog/components/mixin.js";
import Sortable from "sortablejs";
export default {
    name:'tableColumnConfig',
    mixins:[mixin],
    data(){
        return {
          // formData.tableData:[
          //   {fieldCaption:'序号',fieldCustomCaption:'序号',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'门店名称',fieldCustomCaption:'门店名称',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'商品状态',fieldCustomCaption:'商品状态',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'加盟配送',fieldCustomCaption:'加盟配送',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'货号',fieldCustomCaption:'货号',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'品名',fieldCustomCaption:'品名',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'自编码',fieldCustomCaption:'自编码',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'品名',fieldCustomCaption:'品名',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'品牌',fieldCustomCaption:'品牌',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'供应商',fieldCustomCaption:'供应商',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'单位',fieldCustomCaption:'单位',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'规格',fieldCustomCaption:'规格',fieldCustomWidth:100,fieldIsDisplay:true},
          //   {fieldCaption:'进货价',fieldCustomCaption:'进货价',fieldCustomWidth:100,fieldIsDisplay:true},
          // ],
          tableDataCopy:[],//表格数组备份
          tableKey:Date.now(),
          clickBtnName:'',
        }
    },
    created(){
      this.tableDataCopy = arrayObjectCopy(this.dialog.data.tableData);
    },
    mounted(){
      this.initSort();
    },
    methods:{
      inputLimit,
      //固定右，依次插入最后面，也就是移到最下面
      handleNethermost(scope){
        if(scope.row.fieldFixedBack){
          //已固定的取消,如果仅有当前列固定，则改变状态就行
          const fixedIndex = this.dialog.data.tableData.findIndex(item=>item.fieldFixedBack);
          console.log(fixedIndex)
          if(fixedIndex==this.dialog.data.tableData.length-1){
            this.$set(this.dialog.data.tableData[scope.$index],'fieldFixedBack',false);
          }else{
            this.handleChangeTableLocation({
              oldIndex:scope.$index,
              newIndex:fixedIndex
            })
            this.$set(this.dialog.data.tableData[fixedIndex],'fieldFixedBack',false);
          }
        }else{
          this.$set(this.dialog.data.tableData[scope.$index],'fieldFixedFront',false);
          this.$set(this.dialog.data.tableData[scope.$index],'fieldFixedBack',true);
          this.dialog.data.tableData.push(...this.dialog.data.tableData.splice(scope.$index, 1));
        }
      },
      //固定左，
      handleTopside(scope){
        const fixedIndex = this.dialog.data.tableData.findLastIndex(item=>item.fieldFixedFront);
        console.log(fixedIndex)
        if(scope.row.fieldFixedFront){
          //已固定的取消,如果仅有当前列固定，则改变状态就行，如果还有其他固定左的列，则改行默认移到最后一个固定左列的后面。
          if(fixedIndex!=0){
            this.handleChangeTableLocation({
              oldIndex:scope.$index,
              newIndex:fixedIndex
            })
          }
          this.$set(this.dialog.data.tableData[fixedIndex],'fieldFixedFront',false);
        }else{
          //如果之前没有固定左的列，则默认移动到第一个，如果有，则添加到其后
          //console.log(fixedIndex)
          if(fixedIndex==-1){
            this.dialog.data.tableData.unshift(...this.dialog.data.tableData.splice(scope.$index, 1));
          }else{
            this.handleChangeTableLocation({
              oldIndex:scope.$index,
              newIndex:fixedIndex+1
            })
          }
          this.$set(this.dialog.data.tableData[fixedIndex+1],'fieldFixedFront',true);
          this.$set(this.dialog.data.tableData[fixedIndex+1],'fieldFixedBack',false);
        }
      },
      //数组当前元素上移一位或下移一位
      handleMove(status, index, arr){
        // status: 0-表示上移|1-表示下移;index表示当前下标; arr数组
        const data = JSON.parse(JSON.stringify(arr)); // 数组属于引入类型
        if (status == 0) {
            if (index != 0) {
                // 上移第一项无需上移
                data.splice(index - 1, 2, arr[index], arr[index - 1]);
            } else {
                console.log('已经是首项了');
            }
        } else if (status == 1) {
            // 下移最后一项无需下移
            if (index != arr.length) {
                data.splice(index, 2, arr[index + 1], arr[index]);
            } else {
                console.log('已经是最后一项了');
            }
        }
        return data;
    },
    //为表格行区分行类名
    tableRowClassName({ row, rowIndex }){
      if (row.fieldFixedFront) {
        return "filterFront";
      }else if(row.fieldFixedBack){
        return "filterBack";
      }else{
        return "filterCommon";
      }
    },
    //拖拽事件
    initSort(){
      //setTimeout(() => {
        this.$nextTick(() => {
          const el = document.querySelector(
            "#dragTable .el-table__body-wrapper tbody"
          );
          //未固定列的行拖拽
          new Sortable(el, {
            animation: 500,
            handle: '.el-icon-rank',
            ghostClass: "blue-background-class",
            onEnd: async (evt) => {
              this.handleChangeTableLocation(evt,true);
            },
            onChoose(e){
              if(e.item._prevClass.indexOf('filterFront')>-1){
                this.clickBtnName = '.icon-rank-left';
              }
              if(e.item._prevClass.indexOf('filterBack')>-1){
                this.clickBtnName = '.icon-rank-right';
              }
              if(e.item._prevClass.indexOf('filterCommon')>-1){
                this.clickBtnName = '.dragicon';
              }
            },
            //通过类名区分拖拽的区域
            onMove(e) {
              if(this.clickBtnName == '.icon-rank-left'){
                return e.related.className.indexOf("filterFront") > -1;
              }else if(this.clickBtnName == '.icon-rank-right'){
                return e.related.className.indexOf("filterBack") > -1;
              }else{
                return e.related.className.indexOf("filterCommon") > -1;
              }
            }
          });
        });
      //}, 500);
    },
    //表格中当前行插入到任意行
    handleChangeTableLocation(evt,isRefresh){
      const currRow = this.dialog.data.tableData.splice(evt.oldIndex, 1)[0];
      this.dialog.data.tableData.splice(evt.newIndex, 0, currRow);
      this.dialog.data.tableData.forEach((item,index)=>{
        item.columnindex=index;
      })
      if(isRefresh) this.handleRefreshTable();
    },
    handleBlurInput(e,index,key){
      let value = e.target.value;
      if(key === 'fieldCustomWidth'){
        if(Number(value) < 24){
          this.dialog.data.tableData[index][key] = 24;
        }
      }
    },
    //刷新表格
    handleRefreshTable(){
      this.tableKey = Date.now();
      this.initSort();
    },
    //初始化默认值
    handleInit(){
      this.$emit('dialogEvent', 'dialogInit',this.dialog.data.originData)
    },
  },
}
</script>
<style lang="scss" scoped>

.operate{
  .operate-item{
    width:24px;
    height:24px;
    background:#f8f8f9;
    cursor:pointer;
    &:hover{
      background:#ddd;
    }
  }
  .my-el-icon,.svg-icon{
    font-size:16px;
    font-weight:500;
    color:#333;
  }
  .operate-icon{
    background:#289bf0;
    .icon-active{
      fill:#fff!important;
      color:#fff!important;
    }
  }
}
.dialog-footer-box{
  width:100%
}
</style>