// 弹窗组件
<template>
  <div>
    <el-dialog
      :visible.sync="dialog.show"
      v-dialogDrag
      v-loading="!dataObj"
      @open="change(true)"
      @closed="change(false)"
      :class="[
        'view-dialog',
        dialog.hideTitle ? 'hideTitle' : '',
        dialog.type === 'videoPalyer' ? 'hide-body' : '',
      ]"
      :style="cssVar"
      :title="dialog.title"
      customClass="customClass"
      :width="String(dialog.width || '30%')"
      :show-close="!dialog.hideTitle"
      :close-on-click-modal="Boolean(dialog.otherClose)"
      :close-on-press-escape="Boolean(dialog.otherClose)"
      v-bind="$attrs"
      :before-close="handleClose"
      append-to-body
      top="0vh"
    >
      <div v-if="!dataObj" style="height: 250px"></div>
      <template v-if="showContent && dataObj">
        <component
          :keys="String(new Date())"
          ref="myDialog"
          :is="dialog.type"
          v-model="dialog.formData"
          :dialog.sync="dialog"
          :dataObj="dataObj"
          @dialogEvent="dialogEvent"
        />
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { listMenu } from "@/api/system/menu";
import { productListTree } from "@/api/tenant/product/saas.js"; //接口api
import { isEqual } from "lodash";
import { deepCopy, isObject } from "@/utils";
const req = require.context("./components", false, /\.vue$/);
const treeReq = require.context("./components/treedialog", false, /\.vue$/);

const obj = {};
req.keys().forEach((x) => {
  const com = req(x).default;
  obj[com.name] = com;
});
treeReq.keys().forEach((x) => {
  const com = treeReq(x).default;
  obj[com.name] = com;
});
const noRuleDialog = [
  "TreeAndTable",
  "ICGrant",
  "IDGrant",
  "Collection",
  "CardIssuingRecord",
  "VipChangeLevel",
  "VipBatchUpdate",
  "TimeDateSelect",
  "ShopInfo",
  "FactDistribute",
  "O2OTreeGoodsCategory",
];

export default {
  components: { ...obj },
  props: {
    /**
     * @param {String} options.title                      标题
     * @param {Number} options.width                      宽
     * @param {boolean} options.show                      弹窗是否显示
     * @param {String} options.type                       展示的内容
     * @param {String} options.hideTitle                  隐藏标题和 close 按钮
     * @param {Boolean} options.otherClose                是否 可以通过按下 ESC/点击 modal  关闭 Dialog
     * @param {any} options.data                          数据
     * @param {String} options.click                      点击事件文字
     * @param {Boolean, function} options.closeBefore     关闭前事件
     * @param {Boolean, function} options.dataClear       关闭清除数据的方法
     * @param {Object} options.formData                   表单数据对象用于初始化
     * */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dialog: {
      get() {
        this.options.showItem =
          isObject(this.options?.formData) && Object.keys(this.options?.formData)?.length
            ? [this.options?.formData]
            : [];
        return this.options;
      },
      set(e) {
        this.$emit("update:options", e);
      },
    },
    cssVar() {
      return {
        "--dialogW":
          String(this.dialog.width).indexOf("px") >= 0 ||
          String(this.dialog.width).indexOf("vw") >= 0 ||
          this.dialog.width === "auto"
            ? this.dialog.width
            : `${this.dialog.width}px`,
      };
    },
  },
  data() {
    return {
      openData: null,
      dataObj: null,
      showContent: false,
    };
  },
  async created() {
    let dataObj = {};
    if (this.dialog.type === "Remind" || this.dialog.type === "PrintTemplate") {
      dataObj = { menuList: [], menuLevelList: [] };
      try {
        const { data = [] } = await listMenu();
        dataObj.menuLevelList = data.filter((x) => x.menuType !== "F");
        dataObj.menuList = this.handleTree(
          data.filter((x) => x.menuType !== "F"),
          "menuId"
        );
      } catch (error) {}
    } else if (this.dialog.type === "UpgradeLog") {
      try {
        const { data } = await this.getDicts("sys_operating_system");
        dataObj.sys_operating_system = data;
        const { rows } = await productListTree({
          productTypes: "1,2",
          status: 0,
          pageNum: 1,
          pageSize: 200,
        });
        dataObj.productListTree = rows.map((x) => ({
          productId: x.productId,
          productName: x.productName,
          isSunyunApp: x.isSunyunApp,
          productVerIndex: x.productVerIndex,
        }));
      } catch (error) {}
    }

    this.dataObj = dataObj;
    await this.$nextTick();
    try {
      this.$refs.myDialog.onload();
    } catch (error) {}
  },
  methods: {
    handleClose(done = () => {}) {
      // 不需要校验的弹窗
      if (typeof this.dialog?.closeBefore === "function") {
        return this.dialog.closeBefore(done);
      }
      if (
        noRuleDialog.includes(this.dialog.type) ||
        this.dialog?.closeBefore ||
        isEqual(this.dialog.formData, this.openData)
      ) {
        return done();
      }
      this.$confirm("当前页面已有修改，是否保存?", "提示", {
        confirmButtonText: "保存",
        cancelButtonText: "不保存",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$refs.myDialog.dialogEvent(this.dialog.click, false);
          if (res) {
            this.dialogEvent("dialogChange");
          }
        })
        .catch(done);
    },
    // close() {
    //   try {
    //     const dragDom = document.querySelector(".customClass");
    //     console.log("dragDomdragDom", dragDom);
    //     dragDom.style.left = "0px";
    //     dragDom.style.top = "0px";
    //     console.log("dragDomdragDom", dragDom.style);
    //   } catch (error) {}
    // },
    async change(e) {
      this.showContent = e;
      await this.$nextTick();
      if (e) {
        if (!this.openData) {
          this.openData = deepCopy(this.dialog.formData);
        }
      } else {
        this.openData = null;
        if (!noRuleDialog.includes(this.dialog.type)) {
          const formData = {};
          try {
            Object.keys(this.dialog.formData).forEach((x) => {
              formData[x] = "";
            });
          } catch (error) {}
          this.dialog = { ...this.dialog, show: false, formData };
        }
      }
    },
    async dialogEvent(type, row) {
      await this.$nextTick();
      if (type === "formLoad") {
        if (!this.openData) {
          this.openData = deepCopy(row);
        }
        return false;
      }
      const done = () => {
        try {
          this.dialog = { ...this.dialog, show: false };
        } catch (error) {
          this.dialog = { ...this.dialog, show: false };
        }
      };
      if (
        [
          "SourceDialogChange",
          "SourceOperation",
          "SourceCollection",
          "exportFileSuccess",
        ].includes(type)
      ) {
        return this.$emit("handleEvent", type, row);
      }
      if (type === "dialogClose" || type === "dialogChange") {
        if (type === "dialogChange") {
          this.$emit("handleEvent", type, deepCopy(this.dialog));
          done();
        } else {
          this.handleClose(done);
        }
        try {
          await this.$nextTick();
          this.$refs.myDialog.$refs.myForm.$refs.value.clearValidate();
        } catch (error) {}
      } else {
        this.$emit("handleEvent", type, deepCopy(this.dialog));
        if (type !== "delTableItem") {
          done();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.view-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep .el-dialog {
    width: var(--dialogW) !important;
    margin: 0 auto !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  ::v-deep .el-dialog__title {
    font-size: 16px;
    font-weight: bold;
  }

  ::v-deep .el-dialog__close {
    font-size: 16px;
  }

  &.hide-body::v-deep .el-dialog__body {
    padding: 0;
  }

  ::v-deep .dialog-footer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep .dialog-footer {
    border-top: 0.5px solid #dedede;
    padding: 10px 20px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }

  ::v-deep .el-dialog__header {
    padding: 20px;
    height: 60px;
  }

  ::v-deep .el-dialog__header {
    border-bottom: 0.5px solid #dcdcdc;
  }
  ::v-deep .content {
    padding: 10px 20px;
  }
}

.hideTitle {
  ::v-deep .el-dialog__header {
    display: none;
    display: flex;
    align-items: center;
  }
}

.customClass {
  min-width: 350px !important;
}
</style>
