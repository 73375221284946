// import Vue from 'vue'
import store from '@/store'
import DataDict from '@/utils/dict'
import { getDicts as getDicts } from '@/api/system/dict/data'

function searchDictByKey(dict, key) {
  if (key == null && key == "") {
    return null
  }
  try {
    for (let i = 0; i < dict.length; i++) {
      if (dict[i].key == key) {
        return dict[i].value
      }
    }
  } catch (e) {
    return null
  }
}

const appDicts = {
  // sys_normal_disable: [ // 系统开关
  //   {
  //     "dictCode": 1,
  //     "dictLabel": "正常",
  //     "dictValue": "0",
  //     "default": true
  //   },
  //   {
  //     "dictCode": 2,
  //     "dictLabel": "停用",
  //     "dictValue": "1",
  //     "default": false
  //   }
  // ],
  App_sys_yes_no: [ // 系统是否
    {
      "dictCode": 12,
      "dictLabel": "是",
      "dictValue": "Y",
      "default": true
    },
    {
      "dictCode": 13,
      "dictLabel": "否",
      "dictValue": "N",
      "default": false
    }
  ],
  App_recon_flag: [
    {
      "dictCode": 49,
      "dictLabel": "未对账",
      "dictValue": "0",
      "default": false
    },
    {
      "dictCode": 50,
      "dictLabel": "已对账",
      "dictValue": "1",
      "default": false
    }
  ],
  App_is_manage_stock: [
    {
      "dictCode": 104,
      "dictLabel": "否",
      "dictValue": "0",
      "default": false
    },
    {
      "dictCode": 103,
      "dictLabel": "是",
      "dictValue": "1",
      "default": false
    }
  ],
  App_is_allow_sale: [
    {
      "dictCode": 108,
      "dictLabel": "否",
      "dictValue": "0",
      "default": false
    },
    {
      "dictCode": 107,
      "dictLabel": "是",
      "dictValue": "1",
      "default": false
    }
  ],
  App_is_allow_minus: [ // 是否允许负库存
    {
      "dictCode": 110,
      "dictLabel": "否",
      "dictValue": "0",
      "default": false
    },
    {
      "dictCode": 109,
      "dictLabel": "是",
      "dictValue": "1",
      "default": false
    }
  ],
  App_is_use: [ // 是否启用
    {
      "dictCode": 178,
      "dictLabel": "启用",
      "dictValue": "0",
      "default": false
    },
    {
      "dictCode": 179,
      "dictLabel": "禁用",
      "dictValue": "1",
      "default": false
    }
  ],
  App_is_allow_purchase: [ // 是否允许采购
    {
      "dictCode": 105,
      "dictLabel": "是",
      "dictValue": "1",
      "default": false
    },
    {
      "dictCode": 106,
      "dictLabel": "否",
      "dictValue": "0",
      "default": false
    }
  ],
  // sys_show_hide: [ // 菜单状态
  //   {
  //     "dictCode": 4,
  //     "dictLabel": "显示",
  //     "dictValue": "0",
  //     "default": true
  //   },
  //   {
  //     "dictCode": 5,
  //     "dictLabel": "隐藏",
  //     "dictValue": "1",
  //     "default": false
  //   }
  // ],
  // sys_job_status:[
  //   {
  //     "dictCode": 8,
  //     "dictLabel": "正常",
  //     "dictValue": "0",
  //     "default": true
  //   },
  //   {
  //     "dictCode": 9,
  //     "dictLabel": "暂停",
  //     "dictValue": "1",
  //     "default": false
  //   }
  // ],
  // sys_job_group:[{
  //   "dictCode": 10,
  //   "dictLabel": "默认",
  //   "dictValue": "DEFAULT",
  //   "default": true
  // },
  // {
  //   "dictCode": 11,
  //   "dictLabel": "系统",
  //   "dictValue": "SYSTEM",
  //   "default": false
  // }],
  // sys_notice_type:[{ // 通知类型
  //   "dictCode": 14,
  //   "dictLabel": "通知",
  //   "dictValue": "1",
  //   "default": true
  // },
  // {
  //   "dictCode": 15,
  //   "dictLabel": "公告",
  //   "dictValue": "2",
  //   "default": false
  // }],
  // sys_notice_status: [{ // 通知状态
  //   "dictCode": 16,
  //   "dictLabel": "正常",
  //   "dictValue": "0",
  //   "default": true
  // },
  // {
  //   "dictCode": 17,
  //   "dictLabel": "关闭",
  //   "dictValue": "1",
  //   "default": false
  // }],
  // sys_common_status:[{ // 操作状态
  //   "dictCode": 27,
  //   "dictLabel": "成功",
  //   "dictValue": "0",
  //   "default": false
  // },
  // {
  //   "dictCode": 28,
  //   "dictLabel": "失败",
  //   "dictValue": "1",
  //   "default": false
  // } ],
  // goods_status:[{ // 操作状态
  //   "dictCode": 68,
  //   "dictLabel": "上架",
  //   "dictValue": "0",
  //   "default": false
  // },
  // {
  //   "dictCode": 69,
  //   "dictLabel": "下架",
  //   "dictValue": "1",
  //   "default": false
  // }],
  App_del_flag: [{ // 删除标记
    "dictCode": 115,
    "dictLabel": "未删除",
    "dictValue": "0",
    "default": false
  },
  {
    "dictCode": 116,
    "dictLabel": "已删除",
    "dictValue": "1",
    "default": false
  }],
  // machine_working_state:[{ // 机器工作状态
  //   "dictCode": 172,
  //   "dictLabel": "未使用",
  //   "dictValue": "0",
  //   "default": false
  // },
  // {
  //   "dictCode": 173,
  //   "dictLabel": "使用中",
  //   "dictValue": "1",
  //   "default": false
  // }],
  App_is_pos_visible: [{ // 机器工作状态
    "dictCode": 111,
    "dictLabel": "是",
    "dictValue": "1",
    "default": false
  },
  {
    "dictCode": 112,
    "dictLabel": "否",
    "dictValue": "0",
    "default": false
  }],
  // common_status:[{ // 通用状态
  //   "dictCode": 113,
  //   "dictLabel": "正常",
  //   "dictValue": "0",
  //   "default": false
  // },
  // {
  //   "dictCode": 114,
  //   "dictLabel": "停用",
  //   "dictValue": "1",
  //   "default": false
  // }],
  App_membership_level_integral: [{ // 积分
    "dictCode": 180,
    "dictLabel": "是",
    "dictValue": "1",
    "default": false
  },
  {
    "dictCode": 181,
    "dictLabel": "否",
    "dictValue": "0",
    "default": false
  }],
  App_membership_level_discount: [{ // 折扣
    "dictCode": 182,
    "dictLabel": "是",
    "dictValue": "1",
    "default": false
  },
  {
    "dictCode": 183,
    "dictLabel": "否",
    "dictValue": "0",
    "default": false
  }],
  App_membership_level_stored_value: [{ // 储值
    "dictCode": 184,
    "dictLabel": "是",
    "dictValue": "1",
    "default": false
  },
  {
    "dictCode": 185,
    "dictLabel": "否",
    "dictValue": "0",
    "default": false
  }],
  App_membership_level_card_merger: [{ // 另类卡合并
    "dictCode": 186,
    "dictLabel": "是",
    "dictValue": "1",
    "default": false
  },
  {
    "dictCode": 187,
    "dictLabel": "否",
    "dictValue": "0",
    "default": false
  }],
  // in_out_store:[{
  //   "dictCode": 2296,
  //   "dictLabel": "入库",
  //   "dictValue": "1",
  //   "default": false
  // },
  // {
  //   "dictCode": 2297,
  //   "dictLabel": "出库",
  //   "dictValue": "0",
  //   "default": false
  // }],
}
function install() {
  Vue.use(DataDict, {
    metas: {
      '*': {
        labelField: 'dictLabel',
        valueField: 'dictValue',
        request(dictMeta) {
          const storeDict = searchDictByKey(store.getters.dict, dictMeta.type)
          if (storeDict) {
            return new Promise(resolve => { resolve(storeDict) })
          }
          if (appDicts[dictMeta.type]) {
            const value = appDicts[dictMeta.type];
            store.dispatch('dict/setDict', { key: dictMeta.type, value })
            return new Promise(resolve => { resolve(value) })
          }
          return new Promise((resolve, reject) => {
            getDicts(dictMeta.type).then(res => {
              store.dispatch('dict/setDict', { key: dictMeta.type, value: res.data })
              resolve(res.data)
            }).catch(error => {
              reject(error)
            })
          })
        },
      },
    },
  })
}

export default {
  install,
}